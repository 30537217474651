import React, {useState, useEffect} from "react"
import {Typography, Grid, Container, Hidden, Divider} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles';
import styled from "styled-components";
import Sidebar from "../components/sidebar";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import commerto from "../images/commerto.svg"
import etrose from "../images/etrose.svg"
import retalo from "../images/retalo.svg"
import cabroz from "../images/cabroz.svg"
import globe from "../images/globe.svg"
import mark from "../images/mark.svg"
import list from "../images/list.svg"
import truck from "../images/truck.svg"

const StyledBar = styled('div')`
    width: 100%;
    height: 3px;
    z-index: 100;
    background-color: #eb996a;
`;

const useContainerStyles = makeStyles({
    root: {
        padding: '250px 6rem 0 3rem',
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
    }
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        zIndex: 100,
        color: '#fff',
        marginBottom: '1rem'
    }
});

const SectionTwo = () => {
    const containerClasses = useContainerStyles();
    const textClasses = useTextStyles();
    const xsScreen = useMediaQuery("(min-width:0px) and (max-width:599px)");
    const smallScreen = useMediaQuery("(min-width:600px) and (max-width:959px)");
    const mediumScreen = useMediaQuery("(min-width:960px) and (max-width:1279px)");
    const largeScreen = useMediaQuery("(min-width:1280px) and (max-width:1919px)");
    const xlScreen = useMediaQuery("(min-width:1920px)");

    let [slideIn, setSlideIn] = useState(false);

    console.log(mediumScreen);

    function isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        );
    }

    function slideOnScroll() {
        if (isInViewport(document.querySelector('#bar'))) {
            setSlideIn(true)
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', slideOnScroll, {
            passive: true
        });

        return () => {
            document.removeEventListener('scroll', slideOnScroll)
        }
    }, []);

    return (
        <Container className={containerClasses.root} maxWidth={'xl'} id={'brands'}>
            <Hidden smDown={true}>
                <Sidebar/>
            </Hidden>
            <Grid item container spacing={8} direction={'column'}>
                <Grid item container>
                    <Typography variant={'h6'} className={textClasses.root}>
                        Eparia is a carefully-assembled group of like-minded innovators. <br />
                        Rather than working separately, <br /> we’ve all come together to streamline the entire ecommerce
                        experience from beginning to end.
                    </Typography>
                </Grid>
                <Grid item container md={3} xs={5}>
                    <StyledBar id={'bar'}/>
                </Grid>
                <Grid item container spacing={8} justify={(xsScreen || smallScreen) ? 'flex-end' : 'flex-start'}
                      style={(xsScreen || smallScreen) ? null : {paddingLeft: '15rem'}}>
                    <Grid container item lg={5} spacing={4} direction={'column'}>
                        <Grid item container justify={'space-between'} alignItems={'center'}>
                            <Grid item>
                                <img style={{width: '218px', maxHeight: '85px'}} src={etrose} alt={'etrose logo'}/>
                            </Grid>
                            {xsScreen ? null :
                                <Grid item>
                                    <img style={{width: 'auto', height: '50px'}} src={globe} alt={'etrose image'}/>
                                </Grid>}
                        </Grid>
                        <Grid item container>
                            <Typography variant={'h6'} color={'secondary'}>
                                Etrose Holding Limited
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={textClasses.root}>
                                As an entity specializing in the area of Consulting & Talents, we meet the most
                                difficult challenges facing your company by providing modern solutions in the field of
                                personnel and professional consulting.
                            </Typography>
                            <Typography className={textClasses.root}>
                                In our activities, we combine business with new technologies, using the acquired
                                knowledge and experience, as well as constantly observing market trends and reacting to
                                them. We provide high-quality services in the field of strategy, consulting,
                                digitization, technology and operations.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider orientation={'vertical'} style={{height: '100%', backgroundColor: '#25292f'}}/>
                    </Grid>
                    <Grid container item lg={5} spacing={4} direction={'column'}>
                        <Grid item container justify={'space-between'} alignItems={'center'}>
                            <Grid item>
                                <img style={{width: 'auto', height: '70px'}} src={commerto} alt={'etrose logo'}/>
                            </Grid>
                            {xsScreen ? null :
                                <Grid item>
                                    <img style={{width: 'auto', height: '50px'}} src={list} alt={'etrose logo'}/>
                                </Grid>}
                        </Grid>
                        <Grid item container>
                            <Typography variant={'h6'} color={'secondary'}>
                                Commerto Limited
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={textClasses.root}>
                                We provide our consumers with the highest quality products worldwide and take care of
                                consumer satisfaction at every stage of the purchasing decision.
                            </Typography>
                            <Typography className={textClasses.root}>
                                With our multi-channel
                                sales strategy and proven IT systems, we fully control our stores and retail chains to
                                guarantee excellent results.
                            </Typography>
                            <Typography className={textClasses.root}>
                                Flexibility, speed of service, and easy access to key information lead to efficient
                                execution and significant cost reduction.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container md={3} xs={5}>
                    <StyledBar id={'bar'}/>
                </Grid>
                <Grid item container>
                    <Typography variant={'h6'} className={textClasses.root}>
                        The group is still evolving, and plan to add more brands in the future. <br /> Stay tuned.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
};

export default SectionTwo;
